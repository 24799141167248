@use '../styles/common'

.Row
    display: flex
    align-items: center
    &:hover
        .AccWrapper
            &::after
                opacity: 1

.IconWrapper
    margin-right: common.$spacing*4
    display: inline-flex
    align-items: center
    svg
        height: 21px
        color: common.$goodlokDark

.AccWrapper
    display: inline-block
    &::after
        width: 100%
        height: 1px
        content: ''
        display: block
        positon: absolute
        transition: 0.2s opacity ease-out
        bottom: 0
        left: 0
        opacity: 0
        background: common.$goodlokDark

.LinkWrapper
    cursor: pointer
    display: flex
    align-items: center
