@use '../styles/common'

.embla
    cursor: grab
    width: 100%
    background: common.$goodlokLightHover
    &__viewport
        overflow: hidden
        width: 100%

    &__viewport.is-draggable
        cursor: move
        cursor: grab

    &__viewport.is-dragging
        cursor: grabbing

    &__container
        display: flex
        user-select: none
        -webkit-touch-callout: none
        -khtml-user-select: none
        -webkit-tap-highlight-color: transparent
        margin-left: -10px

    &__slide
        position: relative
        min-width: 100%
        padding-left: 10px

    &__slide__inner
        position: relative
        overflow: hidden
        height: 298px
        border-radius: 17px 17px 0 0

        @media (min-width: common.$breakMobile)
            height: 358px

        @media (min-width: common.$breakDesktop)
            height: 378px

        @media (min-width: common.$breakLarge)
            height: 420px

    &__slide__img
        position: absolute
        display: block
        top: 50%
        left: 50%
        width: auto
        min-height: 100%
        min-width: 100%
        max-width: none
        transform: translate(-50%, -50%)

    &__button
        outline: 0
        cursor: pointer
        background-color: transparent
        touch-action: manipulation
        position: absolute
        z-index: 1
        top: 54%
        transform: translateY(-50%)
        width: 30px
        height: 30px
        justify-content: center
        align-items: center
        fill: #1bcacd
        padding: 0
        display: flex
        align-items: center
        border: 1px solid common.$goodlokDark
        border-radius: 100%
        transition: 0.2s background ease-in-out
        background: common.$goodlokDark
        width: 40px
        height: 40px
        opacity: 0.8
        &:hover
            background: common.$goodlokDarkHover

        svg
            width: 18px
            height: 18px
            fill: common.$goodlokLightHover

    &__button:disabled
        cursor: default
        opacity: 0.3

    &__button__svg
        width: 100%
        height: 100%

    &__button__prev
        left: common.$spacing*2
        svg
            transform: rotate(270deg)

            @media (min-width: common.$breakMobile)
                left: 27px

    &__button__next
        right: common.$spacing*2
        svg
            transform: rotate(90deg)

            @media (min-width: common.$breakMobile)
                right: 27px
