@use '../styles/common'

.Container
    display: block
    padding-top: common.$spacing
    overflow: hidden

.ItemNumber
    display: flex
    align-items: center
    svg
        width: 10px
        height: 10px
        stroke: common.$goodlokDark
.Name
    white-space: normal
    margin-bottom: common.$spacing
    @media (min-width: common.$breakMobile)
        margin-bottom: 0

.SmallText
    font-size: 0.6em
    max-width: 20px
    white-space: normal

.ArrItem
    font-size: 0.8em
    opacity: 0.8

    &:not(:last-child)::after
        content: ", "

        @media (min-width: common.$breakMobile)
            font-size: 0.7em
            opacity: 0.8
            white-space: normal

.ExtraItem
    font-size: 0.7em
    opacity: 0.8
    white-space: normal
.Img
    border-radius: 100%
    display: block
    position: relative
    font-size: 14px
    overflow: hidden
    align-items: center
    justify-content: center
    border: 1px solid common.$goodlokDark
    width: 52px
    height: 52px

    div
        position: relative
        width: 100%
        height: 100%

.Titles
    font-size: 13px
    opacity: .7
    padding-bottom: common.$spacing

.Button
    position: relative
    display: flex
    align-items: center
    cursor: pointer
    justify-content: center
    font-size: 1em
    padding: 0

    span
        z-index: 1
        padding: 0 12px

    svg
        fill: common.$goodlokDark
        z-index: 1

    &:hover
        svg
            fill: common.$goodlokLight

    &::after
        content: ''
        display: block
        position: absolute
        width: 48px
        height: 48px
        border: solid 1px
        border-radius: 100%
        opacity: 1
        transition: opacity ease-in-out .2s

    @media (min-width: common.$breakMobile)
        padding: common.$spacing*5 10px

        &::after
            content: ''
            display: block
            position: absolute
            width: auto
            height: auto
            top: .2em
            bottom: .2em
            left: .1em
            right: .1em
            border: solid 1px
            border-radius: 100%
            opacity: 1
            transition: opacity ease-in-out .2s

    &.active,
    &:hover,
    &:focus
        color: common.$goodlokLight
        &::after
            background: common.$goodlokDark

.ButtonSet
    color: common.$goodlokLight
    &::after
        background: common.$goodlokDark

.Pointer
    cursor: pointer

.DesktopHidden
    display: block
    z-index: 1
    height: 21px
    @media (min-width: common.$breakMobile)
        display: none

.MobileHidden
    display: none
    @media (min-width: common.$breakMobile)
        display: block

.Tooltip
    +common.tooltip
    &:hover
        .TooltipText
            visibility: visible

.TooltipText
    +common.tooltext

.WaitingButton
    color: common.$goodlokLight
    &::after
        background: common.$goodlokDark
