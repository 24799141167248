@use '../styles/common'

.Section
    display: grid
    grid-template-columns: 1fr
    gap: 1.5em

    @media (min-width: common.$breakMobile)
        grid-template-columns: 1fr 1fr
        gap: 2.5em

        @media (min-width: common.$breakDesktop)
            grid-template-columns: 1fr 1fr 1fr
            gap: 2.5em

.SectionWrapper
    &::after
        content: ""
        display: block
        width: 100%
        background: black
        margin: common.$padding*2 0 common.$padding
        height: 1px

.Button
    position: relative
    display: flex
    align-items: center
    cursor: pointer
    font-size: 1.2em
    padding: 0

    svg
        height: 12px
        width: 12px
        margin-right: .4em
        stroke: common.$goodlokDark

    @media (min-width: common.$breakMobile)
        font-size: 1.6em
        padding: common.$spacing*6

        svg
            height: 18px
            width: 18px

        &::after
            content: ''
            display: block
            position: absolute
            top: .2em
            bottom: .2em
            left: .1em
            right: .1em
            border: solid 1px
            border-radius: 100%
            opacity: 0
            transition: opacity ease-in-out .2s

        &.active,
        &:hover,
        &:focus
            &::after
                opacity: 1

.Header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: common.$spacing*5

    @media (min-width: common.$breakMobile)
        margin-bottom: common.$spacing*2

.Title
    font-size: 1.4em
    font-weight: 600

    @media (min-width: common.$breakMobile)
        font-size: 1.8em

        @media (min-width: common.$breakDesktop)
            font-size: 2em
