@use '../styles/common'

.Container
	max-width: common.$containerWidth
	margin-left: auto
	margin-right: auto
	padding: common.$padding

.LogoWrapper
	padding: common.$spacing*5
	display: flex
	align-items: center
	justify-content: center

.Login
	text-align: center
	margin-top: 10vh
	font-size: 1.2em
	padding: common.$padding
	input
		outline: none
		font-size: 1.6em
		padding: common.$spacing*2
		border: 1px solid common.$goodlokLightHover
		border-radius: 4px
		max-width: 300px

	@media (min-width: common.$breakMobile)
		font-size: 1.2em

.Button
	position: relative
	display: flex
	align-items: center
	cursor: pointer
	font-size: 1.4em
	margin: common.$spacing*6 auto
	padding: common.$spacing*5
	border-radius: 100%
	border: 1px solid
	color: common.$goodlokDark
	transition: opacity ease-in-out .2s

	&.active,
	&:hover,
	&:focus
		color: common.$goodlokLight
		background: common.$goodlokDark

	@media (min-width: common.$breakMobile)
		font-size: 1.6em
