@use '../vars'

=topContent()
    display: flex
    justify-content: space-between
    border-bottom: vars.$border
    align-items: center
    padding: vars.$padding
    background: vars.$goodlokLight

=info()
    border: 1px solid vars.$goodlokDark
    flex: 0 0 auto
    margin-right: vars.$spacing*2
    border-radius: 100%
    margin-top: 3px
    width: 22px
    height: 22px
    display: flex
    align-items: center
    justify-content: center

    span
        font-size: 16px

=contentWrapper()
    padding: vars.$padding

=vokativ()
    font-weight: vars.$fwBold
    text-transform: capitalize
