$goodlokLight: #EDEAE5
$goodlokLightHover: #DBD7D2
$goodlokBgLight: #E2DFDA
$goodlokDark: #28282A
$goodlokRed: #ED3129
$goodlokDarkHover: #1A1A1B
$black: #000
$white: #FFF
$green: #2E8B57

$spacing: 4px

$padding: 16px

$containerWidth: 1190px

$breakMobile: 768px
$breakDesktop: 1200px
$breakLarge: 1500px
$breakExtraLarge: 1900px

$border: 2px solid #28282A

$pageWidth: 2000px
$narrowPageWidth: 800px

$fwNormal: 400
$fwBold: 600

$imageBorderRadius: calc(5px + 1vw)

$imagePlaceholderColor: rgba($goodlokDark, .05)
