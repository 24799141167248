@use '../styles/common'

.ImgWrapper
    height: 260px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    border-bottom: 2px solid common.$goodlokDark
    div
        position: relative
        width: 100%
        height: 100%

.TopContent
    +common.topContent
    font-size: 1.2em
    svg
        width: 14px
        height: 27px

    @media (min-width: common.$breakMobile)
        font-size: 1em

    @media (min-width: common.$breakLarge)
        font-size: 0.7em

.Card
    overflow: hidden
    border-radius: 20px
    position: relative
    cursor: pointer
    height: 100%
    border: common.$border
    @media (min-width: common.$breakMobile)
        font-size: 1.2em

    @media (min-width: common.$breakDesktop)
        font-size: 1.4em

    @media (min-width: common.$breakLarge)
        font-size: 2em

    &:hover
        .Img
            transform: scale(1.05)

.Img
    transition: 0.2s transform ease-out

.Placeholder
    +common.placeholderUser

.ContentWrapper
    +common.contentWrapper
    display: block
    font-size: 1.3em
    @media (min-width: common.$breakMobile)
        font-size: 1.2em

    @media (min-width: common.$breakDesktop)
        font-size: 1.15em

        @media (min-width: common.$breakLarge)
            font-size: 0.9em

.Vokativ
    +common.vokativ

.ArrowUp,
.ArrowDown
    margin-left: auto
    svg
        width: 26px
        height: 20px

.ArrowDown
    display: none
    transform: rotate(180deg)

.Info
    +common.info

.Note
    position: relative
    display: flex
    align-items: flex-end
    padding: common.$padding
    padding-top: 0
    margin-top: -8px

.NotePreview
    padding: 0
    font-size: 1em
    display: flex
    text-align: left
    align-items: flex-start

.NoteText
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    width: 100%

.NoteBody
    display: flex
    align-items: center

.NoteTextExpanded
    position: absolute
    bottom: 0
    left: 0
    max-height: 320px,
    padding: common.$padding
    padding-right: common.$spacing*5
    width: 100%
    background: common.$goodlokBgLight
    z-index: 2
    border-radius: 0 0 20px 20px
    width: 100%
    .ArrowDown
        display: block

.LimitedText
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical

.ExpandedText
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 10
    -webkit-box-orient: vertical

.ConfirmButton
    position: absolute
    top: common.$spacing*3
    right: common.$spacing*3
    z-index: 2
    width: 56px
    height: 56px
    transition: .2s all ease-in-out
    display: flex
    font-size: 16px
    align-items: center
    justify-content: center
    border: 1px solid common.$goodlokDark
    border-radius: 100%
    color: common.$goodlokDark
    background: common.$goodlokLight
    &:hover
        color: common.$white
        background: common.$goodlokDark

.BtnIcon
    display: flex
    justify-content: center
    fill: common.$green
    & svg
