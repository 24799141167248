@use '../styles/common'

.SearchBar
	min-height: 84px
	width: 100%
	background: common.$goodlokBgLight
	padding: common.$spacing*3 common.$spacing*2

	@media (min-width: common.$breakMobile)
		min-height: 92px
		padding: common.$spacing*5 common.$padding

.InputWrapper
	position: relative
	padding: 0 common.$spacing*2
	max-width: common.$containerWidth
	width: 100%
	margin-left: auto
	margin-right: auto

	@media (min-width: common.$breakMobile)
		padding: 0 common.$padding

	&:focus-within
		.CloseBtn
			opacity: 1

	input
		width: 100%
		border-radius: 0
		margin-left: auto
		margin-right: auto
		font-size: 1.45em
		min-height: 52px
		background: transparent
		border: 0
		border-bottom: 1px solid common.$goodlokDark
		outline: none

		@media (min-width: common.$breakMobile)
			font-size: 2.2em
			min-height: 68px

		@media (min-width: common.$breakDesktop)
			font-size: 2.8em
			min-height: 92px

.CloseBtn
	position: absolute
	right: common.$spacing*2
	top: 14px
	opacity: 0.5
	padding: 0
	transition: 0.2s opacity ease-in

	svg
		width: 24px
		height: 24px
		stroke: common.$goodlokDark

	@media (min-width: common.$breakMobile)
		top: 20px
		right: common.$padding

		svg
			width: 30px
			height: 30px

	@media (min-width: common.$breakDesktop)
		top: 32px

		svg
			width: 36px
			height: 36px
