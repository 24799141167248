@use '../styles/common'

.Row
	display: grid
	align-items: center
	width: 100%
	min-height: 48px
	grid-template-columns: 1fr 3fr 1fr
	gap: 0.6em
	font-size: 1.3em
	margin-bottom: common.$spacing

	@media (min-width: common.$breakMobile)
		margin: common.$spacing*2 common.$spacing*1
		font-size: 1.4em
		gap: 0.4em
		grid-template-columns: 0.9fr 3fr 2.5fr 0.5fr 2fr

	@media (min-width: common.$breakDesktop)
		font-size: 1.5em
		grid-template-columns: 0.7fr 3.3fr 3fr 1fr 2fr

	@media (min-width: common.$breakLarge)
		font-size: 1.6em
		gap: 0.5em
		grid-template-columns: 0.5fr 2fr 1.5fr 0.5fr 0.5fr 1.2fr

.RowBody
	padding-bottom: common.$spacing*3
	margin-bottom: common.$spacing*3
	border-bottom: 1px solid common.$goodlokBgLight

	@media (min-width: common.$breakMobile)
		border-bottom: none
		padding-bottom: 0
		margin-bottom: 0

.MobileHidden
	display: none
	@media (min-width: common.$breakMobile)
		display: block

.ArrItemGroup
	display: flex
	align-items: center
	margin-top: 2px
	flex-wrap: wrap

.DesktopHidden
	display: flex
	@media (min-width: common.$breakMobile)
		display: none

.ArrItem
	font-size: 0.8em
	opacity: 0.8
	display: flex
	align-items: center
	white-space: normal
	&:not(:last-child)::after
		content: ""
		display: inline-block
		width: 4px
		height: 4px
		background: common.$goodlokDark
		border-radius: 100%
		margin-left: 6px
		margin-right: 6px

.RowItem
	line-height: 1.1
	&:last-child
		padding: 0
		text-align: center
		display: flex
		justify-content: center

	@media (min-width: common.$breakMobile)
		line-height: 1.36
		text-align: left
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		padding: 0 4px 0 0

		&:last-child
			text-align: left

.LowerImportance
	font-size: 0.9em

.Pointer
	cursor: pointer

.ItemNumber
	overflow: visible
	svg
		width: 15px
		height: 27px
		margin-left: common.$spacing
