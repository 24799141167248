@use '../styles/common'
.Modal
    position: fixed
    z-index: 4
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.3)

.ModalContent
    z-index: 3
    background-color: #fefefe
    margin: common.$spacing*12 common.$padding*1
    padding: common.$spacing*4
    border-radius: 33px
    max-width: 600px
    @media (min-width: common.$breakMobile)
        margin: common.$spacing*10 common.$padding*2

        margin: 32px auto
        padding: common.$spacing*6

.BtnDecline
    text-align: right
    padding: common.$spacing*4 common.$spacing*2 common.$spacing
    button
        position: relative
        font-size: 0.9em
        padding: 0
        &::after
            width: 100%
            height: 1px
            position: absolute
            content: ''
            top: calc(100% + 4px)
            transition: 0.2s opacity ease-in-out
            opacity: 0
            left: 0
            background: common.$goodlokDark
    svg
        stroke: common.$goodlokDark
        width: 10px
        height: 10px
        margin-right: common.$spacing*2

    &:hover
        button
            &::after
                opacity: 1
