@use '../vars'

=tooltip()
    position: relative
    display: inline-block

=tooltext()
    visibility: hidden
    background-color: rgba(40,40,42,0.7)
    padding: 4px 8px
    color: #fff
    text-align: center
    font-size: 0.7em
    border-radius: 6px
    position: absolute
    z-index: 1
    top: -2px
    height: 100%
    left: 130%

=tooltextTop()
    top: -125%
    left: -50%
