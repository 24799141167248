@use '../styles/common'

.ProfileCard
    border-radius: 20px
    height: 100%
    position: relative
    overflow: hidden
    border: common.$border
    font-size: 1.2em

    @media (min-width: common.$breakMobile)
        font-size: 1.3em

    @media (min-width: common.$breakDesktop)
        font-size: 1.4em

    @media (min-width: common.$breakLarge)
        font-size: 1em

.ImgGroupWrapper
    height: 300px
    display: flex
    z-index: 1
    position: relative
    overflow: hidden
    position: relative
    border-radius: 17px 17px 0 0
    align-items: center
    width: 100%
    justify-content: center
    border-bottom: common.$border

    @media (min-width: common.$breakMobile)
        height: 360px

    @media (min-width: common.$breakDesktop)
        height: 380px

    @media (min-width: common.$breakLarge)
        height: 410px

.ContentWrapper
    +common.contentWrapper
    font-size: 1.3em

    @media (min-width: common.$breakLarge)
        font-size: 1.9em

.TagGroup
    line-height: 1.1

.TopContent
    +common.topContent
    span
        display: flex
        align-items: center
        font-size: 1em

        @media (min-width: common.$breakLarge)
            font-size: 1.4em

    svg
        height: 28px

.BottomContent
    background: common.$goodlokLight

.Note
    position: relative
    display: flex
    align-items: flex-start
    padding: common.$padding
    font-size: 1.2em
    border-top: 2px solid common.$goodlokDark

    @media (min-width: common.$breakMobile)
        align-items: flex-start

.Info
    +common.info
    margin-top: common.$spacing

.Vokativ
    +common.vokativ

.Tag,
.Aliases
    font-size: 0.7em
    @media (min-width: common.$breakMobile)
        font-size: 0.8em

.Tag
    &:not(:last-child)::after
        content: ""
        display: inline-block
        width: 6px
        height: 6px
        background: common.$goodlokDark
        border-radius: 100%
        margin: 2px common.$spacing*2

.BtnConfirm,
.BtnPin
    border-top: 2px solid common.$goodlokDark
    background: common.$goodlokBgLight
    transition: 0.2s background ease-in-out

    button
        width: 100%
        font-size: 18px
        text-transform: uppercase
        border-radius: 0 0 17px 17px
        padding: common.$padding
        transition: 0.2s color ease-in-out

        &:hover
            color: common.$black

        @media (min-width: common.$breakMobile)
            padding: common.$spacing*5

    &:hover
        background: common.$goodlokLightHover

.BtnPay
    border-top: 2px solid common.$goodlokDark
    background: common.$goodlokDark
    transition: 0.2s background ease-in-out

    button
        width: 100%
        font-size: 18px
        padding: common.$padding
        text-transform: uppercase
        color: common.$goodlokLight
        transition: 0.2s color ease-in-out

        @media (min-width: common.$breakMobile)
            padding: common.$spacing*5

    &:hover
        background: common.$goodlokDarkHover
        button
            color: common.$white

.BtnPin
    button
        border-radius: 0 0 17px 17px

.Placeholder
    +common.placeholderUser

.Social
    border-top: common.$border
    padding: common.$padding
    background: common.$goodlokLight

.Bold
    font-weight: common.$fwBold

.CloseBtn
    position: absolute
    top: common.$spacing*2
    right: common.$spacing*2
    background: common.$goodlokDark
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center
    transition: background 0.2s ease-in-out
    padding: 19px
    z-index: 2
    svg
        width: 16px
        height: 16px
        position: absolute
        transition: stroke 0.2s ease-in-out

        stroke: common.$goodlokLight
        z-index: 3

    &:hover
        background: common.$goodlokDarkHover
        svg
            stroke: common.$white

    @media (min-width: common.$breakMobile)
        top: common.$spacing*2
        right: common.$spacing*2

.Credits
    margin-left: 12px
    display: flex
    align-items: center
    &::before
        content: ''
        display: block
        background: common.$goodlokDark
        width: 6px
        height: 6px
        border-radius: 100%
        margin-right: 12px

.SmallText
    font-size: 0.6em
    padding: common.$spacing 0
    vertical-align: middle
    & svg
        stroke: common.$goodlokDark
        width: 10px
        height: 10px

.Tooltip
    +common.tooltip
    &:hover
        .TooltipText
            visibility: visible

.TooltipText
    +common.tooltext

.TooltipTextTop
    +common.tooltextTop
